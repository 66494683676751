import routes from "./routes";
import Layout from "./layout";
import { Link, Route, Switch } from "wouter";

export default function LandingRouter() {
  return (
    <Layout>
      <div className="flex flex-col w-90% h-90% items-center justify-center bg-white rounded-xl self-center space-y-4">
        <img
          className="w-30% <md:(w-70%)"
          src="/favicon.png"
          alt="favicon.png"
        />
        <span>This website has no landing page.</span>
      </div>
    </Layout>
  );
}
